<template>
    <standard-page title="Create New Process">
        <template v-slot:breadcrumb>
            <span><router-link :to="{ name: 'provenances-home'}">Provenance</router-link></span>
            <span>New Process</span>
        </template>
        <template v-slot:content>
            <div class="az-content-label mg-b-5">Start New Process</div>
            <div class="mg-b-20" v-html="$DEFINITIONS.provenance.newProcess"></div>
            <form @submit.prevent="handleSubmit">
                <div class="pd-30 pd-sm-40 bg-gray-200 wd-xl-75p">
                    <div class="row row-xs">
                        <div class="col-md-10">
                            <multiselect :class="{'is-invalid': submitted && errors.has('service')}"
                                         :options="available_definition"
                                         :selectLabel="''"
                                         @input="getProcess()"
                                         class="form-control rounded"
                                         data-vv-as="Definition"
                                         id="definition"
                                         label="Definition"
                                         name="definition"
                                         open-direction="bottom"
                                         placeholder="Select Process Definition"
                                         v-model="process_definition"
                                         v-validate="'required'">
                                <template slot="singleLabel" slot-scope="props">
                                    {{props.option.name}}
                                </template>
                                <template slot="option" slot-scope="props">
                                    <div class="option__desc">
                                        <h6>{{ props.option.name | capitalize }}</h6>
                                        <span><strong>Created By:</strong> {{ props.option.created_by }}</span>
                                    </div>
                                </template>
                            </multiselect>
                            <div class="invalid-feedback" v-if="submitted && errors.has('service')">{{ errors.first('service') }}</div>
                        </div>
                        <div class="col-md mg-t-10 mg-md-t-0">
                            <button class="btn rounded btn-az-primary btn-block" type="submit">Submit</button>
                        </div>
                    </div>
                </div>
            </form>
        </template>
    </standard-page>
</template>

<script>
    import EventBus from "@/event-bus";
    import Multiselect from 'vue-multiselect';

    export default {
        name: "AvailableDefinitions",
        components: {Multiselect},
        data() {
            return {
                submitted: false,
                available_definition: [],
                process_definition: null,
                process: {
                    name: null
                },
            }
        },
        mounted() {
            this.getAvailableDefinitions();
        },
        methods: {
            async getAvailableDefinitions() {
                try {
                    let {data} = await this.$provenance.get(`provenance/registries/available_definitions?page=0&per_page=100`,);
                    this.available_definition = data.process_definitions;
                } catch (e) {

                }
            },
            async getProcess() {
                if (this.process_definition) {
                    try {
                        let {data} = await this.$provenance.get(`provenance/registries/${this.process_definition.registry}/definitions/${this.process_definition.process_definition}/processes?page=0&per_page=100`);
                        let d = new Date();
                        let y = d.getFullYear();
                        let m = d.getMonth() + 1;
                        this.process.name = `PROCESS-${this.process_definition.process_definition}-${y}-${m}-${(data.total + 1).toString()}`;
                    } catch (e) {

                    }
                }
            },
            handleSubmit(e) {
                this.submitted = true;
                this.$validator.validate()
                        .then(valid => {
                            if (valid) {
                                this.createProcess()
                            }
                        });
            },
            async createProcess() {
                if (this.process.name) {
                    try {
                        EventBus.$emit('openLoader');
                        let {data} = await this.$provenance.post(`provenance/registries/${this.process_definition.registry}/definitions/${this.process_definition.process_definition}/processes`, this.process);
                        this.$toastr.s("Process Successfully created!", 'Success');
                        // await this.addProcessStep(data.id);
                        this.$router.go(-1);
                    } catch (e) {
                        this.$toastr.e("Process creation failed", 'Error');
                    } finally {
                        EventBus.$emit('closeLoader');
                    }
                } else {
                    this.$toastr.e("Please provide a name first!", 'Failed');
                }
            },
            async addProcessStep(processid) {
                try {
                    await this.$provenance.post(`provenance/registries/${this.process_definition.registry}/definitions/${this.process_definition.process_definition}/processes/${processid}/process_steps`, {
                        processDefinition_step: this.firstProcessDefinition ? this.firstProcessDefinition.processDefinition_step : null,
                        attributes: []
                    });
                } catch (e) {
                } finally {
                }
            },
        }
    }
</script>

<style scoped>

</style>